export const useCalculator = () => {

  const { refParams, getCurrentParams, defaultLeasingParams } = useLeasingParams();
  const api = useAPI();

  const getInstallment = (cars) => new Promise(resolve => {
    if (!cars) {
      resolve([]);
      return;
    }

    let oneCar = false;
    const params = getCurrentParams();

    params.cars = [];

    if (typeof cars.data.pop === 'undefined') {
      oneCar = true;
      params.cars.push({id: 1, price: toRaw(cars.data.price)})
    } else {
      cars.data.forEach((car, idx) => {
        car.idx = idx;
        if (car.price) params.cars.push({id: idx, price: toRaw(car.price)});
      })
    }

    api.request(
      "/calculate/",
      params,
      'post'
    ).then(result => {
      if (result.data?.data?.cars) {
        const calculatedInstallments = result.data.data.cars;
        if (!oneCar) {
          cars.data.forEach((car) => {
            const [installment] = calculatedInstallments.filter(i => i.id === car.idx);
            if (installment?.installment) {
              car.installment = installment?.installment;
            }
          })
        } else {
          const [installment] = calculatedInstallments.filter(i => i.id === 1);
          cars.data.installment = installment?.installment;
        }
        resolve(result.data.data.cars);
      } else {
        console.log('🔴')

        resolve([]);
      }
    });
  })

  return {
    getInstallment
  }
}
